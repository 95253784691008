import "../styles/JobDetails.css";
import { useParams, useSearchParams } from "react-router-dom";
import { dataEnglish } from "../../data";
import HeroImage from "../../images/job_hero.jpg";

const JobDetail = () => {
  const [searchParams] = useSearchParams();

  const language = searchParams.get("lang");

  function getVacancyData(language) {
    return dataEnglish.vacancies[language] || dataEnglish.vacancies.en;
  }
  const vacancyData = getVacancyData(language);

  const { id } = useParams();
  const jobId = parseInt(id.split("-").pop());

  const formData = vacancyData.formData;
  const jobData = vacancyData.positions.find(
    (position) => position.id === jobId
  );
  if (!jobData) {
    return <h2>Job not found</h2>;
  }

  return (
    <main>
      <section id="section-1">
        <div className="job-intro-wrapper">
          <img src={HeroImage} alt="Andrama hero" />
          <div className="content-wrapper">
            <h1>{jobData.positionTitle}</h1>
          </div>
        </div>
      </section>
      <section id="section-2">
        <div className="job-description-wrapper">
          <h2>{jobData.positionTitle}</h2>
          <p>{jobData.intro}</p>
          <h4>{jobData.requirements.sectionTitle}</h4>
          <ul>
            {jobData.requirements.list.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
          {jobData.responsibilities && (
            <>
              <h4>{jobData.responsibilities.sectionTitle}</h4>
              <ul>
                {jobData.responsibilities.list.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </>
          )}
          <h4>{jobData.offers.sectionTitle}</h4>
          <ul>
            {jobData.offers.list.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
          <p className="endnote">{jobData.endNote}</p>
        </div>
      </section>
      <section id="section-3">
        <div className="job-form-wrapper">
          <h2>{formData.formTitle}</h2>
          <form
            id="application-form"
            method="post"
            action="https://www.andrama.se/job_application.php"
            acceptCharset="UTF-8"
            encType="multipart/form-data"
          >
            <input
              name="realname"
              placeholder={formData.fullName}
              className="form-input"
              required
            />

            <input
              name="job_position"
              placeholder={formData.jobPosition}
              className="form-input"
              required
            />

            <input
              type="email"
              name="email"
              placeholder={formData.email}
              className="form-input"
              required
            />

            <input
              type="tel"
              name="phone"
              placeholder={formData.phone}
              className="form-input"
              required
            />

            <input
              type="file"
              name="cv"
              accept=".pdf,.doc,.docx"
              className="form-input"
              required
            />

            <textarea
              name="cover_letter"
              cols="40"
              rows="10"
              placeholder={formData.coverLetter}
              className="form-input"
              required
            ></textarea>

            <button type="submit" className="form-input">
              {formData.submitButton}
            </button>

            <input type="hidden" name="recipient" value="job@andrama.se" />
            <input type="hidden" name="subject" value="Job Application" />
            <input
              type="hidden"
              name="redirect"
              value="https://www.andrama.se/submit-success"
            />
            <input
              type="hidden"
              name="missing_fields_redirect"
              value="https://www.andrama.se/submit-error"
            />
            <input
              type="hidden"
              name="required"
              value="realname,job_position,email,phone,cv,cover_letter"
            />
          </form>
        </div>
      </section>
    </main>
  );
};

export default JobDetail;
