import "./styles/Footer.css";
import { dataEnglish } from "../data.js";
import { useState } from "react";

const Footer = () => {
  const [data] = useState(dataEnglish.footer);

  return (
    <footer className="front">
      <div id="copyright">
        <p>{data.copyright}</p>
      </div>
    </footer>
  );
};

export default Footer;
