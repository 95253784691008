import "./styles/Contact.css";
import { useState } from "react";
import { dataEnglish } from "../data.js";
import HeroImage from "../images/stalazas.jpg";
import { sectionContentAnimate } from "../helpers.js";
import { motion } from "framer-motion";

const Contact = () => {
  const [data] = useState(dataEnglish.contact);

  return (
    <main>
      <section id="section-1">
        <div className="contacts-intro-wrapper">
          <img src={HeroImage} loading="lazy" alt="Andrama hero" />
          <div className="content-wrapper">
            <h1>{data.title}</h1>
          </div>
        </div>
      </section>
      <motion.section
        id="section-2"
        variants={sectionContentAnimate}
        initial={"hidden"}
        whileInView={"shown"}
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="general-info-wrapper">
          <h2>Andrama Bygg AB</h2>
          <div>
            <p>
              Org.nr: <span>556947-9131</span>
            </p>

            <p>
              VAT nr: <span>SE556947913101</span>
            </p>

            <p>
              Invoices: <span>faktura@andrama.se</span>
            </p>

            <p>
              Address: <span>Box 7665, 103 94 Stockholm</span>
            </p>
          </div>
        </div>
      </motion.section>

      <motion.section
        id="section-3"
        variants={sectionContentAnimate}
        initial={"hidden"}
        whileInView={"shown"}
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="form-wrapper">
          <h2>Have a question? Write to us.</h2>
          <form
            id="contact-form"
            method="post"
            action="https://www.andrama.se/contact.php"
            acceptCharset="UTF-8"
            encType="multipart/form-data"
          >
            <input
              name="realname"
              placeholder={data.fullName}
              className="form-input"
              required
            />
            <input
              name="email"
              placeholder={data.email}
              className="form-input"
              required
            />
            <textarea
              cols="40"
              rows="10"
              name="message"
              placeholder={data.message}
              className="form-input"
              required
            ></textarea>
            <button type="submit" className="form-input">
              {data.submit}
            </button>
            <input type="hidden" name="recipient" value="info@andrama.se" />
            <input type="hidden" name="subject" value="Subject" />
            <input
              type="hidden"
              name="redirect"
              value="https://www.andrama.se/submit-success"
            />
            <input
              type="hidden"
              name="missing_fields_redirect"
              value="https://www.andrama.se/submit-error"
            />
            <input
              type="hidden"
              name="required"
              value="realname,email,Message"
            />
          </form>
        </div>
      </motion.section>
    </main>
  );
};

export default Contact;
