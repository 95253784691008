import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import FrontPage from "../pages/FrontPage.js";
import AboutPage from "../pages/AboutPage.js";
import ProjectsPage from "../pages/ProjectsPage.js";
import ContactPage from "../pages/ContactPage.js";
import SubmitSuccessPage from "../pages/SubmitSuccessPage";
import SubmitErrorPage from "../pages/SubmitErrorPage";
import ApplyFormPage from "../pages/ApplyFormPage";
import VacanciesPage from "../pages/VacanciesPage.js";
import JobDetailPage from "../pages/JobDetailPage.js";

const AnimatedRoutes = () => {
  const location = useLocation();

  const useDocumentTitle = (title) => {
    useEffect(() => {
      document.title = title;
    }, [title]);
  };

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={<FrontPage useDocumentTitle={useDocumentTitle} />}
        />
        <Route
          path="/about"
          element={<AboutPage useDocumentTitle={useDocumentTitle} />}
        />
        <Route
          path="/projects"
          element={<ProjectsPage useDocumentTitle={useDocumentTitle} />}
        />
        <Route
          path="/contact"
          element={<ContactPage useDocumentTitle={useDocumentTitle} />}
        />
        <Route
          path="/vacancies"
          element={<VacanciesPage useDocumentTitle={useDocumentTitle} />}
        />
        <Route
          path="/vacancies/:id"
          element={<JobDetailPage useDocumentTitle={useDocumentTitle} />}
        />
        <Route
          path="/submit-success"
          element={<SubmitSuccessPage useDocumentTitle={useDocumentTitle} />}
        />
        <Route
          path="/submit-error"
          element={<SubmitErrorPage useDocumentTitle={useDocumentTitle} />}
        />
        <Route
          path="/apply-form"
          element={<ApplyFormPage useDocumentTitle={useDocumentTitle} />}
        />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
