import "./Pages.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Vacancies from "../components/Vacancies";
import { motion } from "framer-motion";

const VacanciesPage = (props) => {
  const { useDocumentTitle } = props;
  useDocumentTitle("ANDRAMA -Join Us");
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      id="vacancies-wrapper"
    >
      <Header />
      <Vacancies />
      <Footer />
    </motion.div>
  );
};

export default VacanciesPage;
