import Header from "../components/Header";
import Footer from "../components/Footer";
import SubmitSuccess from "../components/SubmitSuccess";

const SubmitSuccessPage = (props) => {
  const { useDocumentTitle } = props;
  useDocumentTitle("ANDRAMA - Your Message Has Been Sent");
  return (
    <div id="submit-wrapper">
      <Header />
      <SubmitSuccess />
      <Footer />
    </div>
  );
};

export default SubmitSuccessPage;
