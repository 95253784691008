import "./styles/Contact.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { dataEnglish } from "../data.js";

const SubmitError = () => {
  const [data] = useState(dataEnglish.submitError);

  return (
    <div id="submit-error">
      <h2>{data.message}</h2>

      <Link to="/contact">
        <button>{data.button}</button>
      </Link>
    </div>
  );
};

export default SubmitError;
