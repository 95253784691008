import "./Pages.css";
import HeaderFront from "../components/Header";
import Clouds from "../components/Clouds";
import FrontLogo from "../components/FrontLogo";
import Footer from "../components/FooterFront";
import { motion } from "framer-motion";

const FrontPage = (props) => {
  const { useDocumentTitle } = props;
  useDocumentTitle("ANDRAMA");
  return (
    <motion.div
      id="front-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <HeaderFront />
      <Clouds />
      <FrontLogo />
      <Footer />
    </motion.div>
  );
};
export default FrontPage;
