import "./styles/Header.css";
import { NavLink, Link } from "react-router-dom";
import { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { dataEnglish } from "../data.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import LogoLong from "./LogoLong.jsx";
import LogoMenu from "../images/logo.svg";

const Header = () => {
  const [data] = useState(dataEnglish.header);
  const [burgerOpen, setBurgerOpen] = useState(false);
  const location = useLocation();
  const [showLogo] = useState(location.pathname === "/" ? false : true);

  const burgerList = useRef(null);

  const handleToggle = () => {
    setTimeout(() => {
      setBurgerOpen((prev) => !prev);
    }, 250);
  };

  const onClose = () => {
    burgerList.current.classList.add("onClose");
    burgerList.current.classList.remove("onOpen");
  };

  return (
    <header>
      <div className="front-links">
        {showLogo && (
          <div className="logo-wrapper">
            <Link to="/">
              <LogoLong />
            </Link>
          </div>
        )}
        <NavLink className="nav-link" to="/about">
          {data.about}
        </NavLink>
        <NavLink className="nav-link" to="/projects">
          {data.projects}
        </NavLink>
        <NavLink className="nav-link" to="/vacancies">
          {data.vacancies}
        </NavLink>
        <NavLink className="nav-link" to="/contact">
          {data.contact}
        </NavLink>
      </div>
      <div className="front-contacts">
        <a href="mailto:info@andrama.se" target="_blank" rel="noreferrer">
          <div>
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
          <span>info@andrama.se</span>
        </a>

        <a
          href="https://www.linkedin.com/company/andrama-bygg/"
          target="_blank"
          rel="noreferrer"
        >
          <div>
            <FontAwesomeIcon icon={faLinkedinIn} />
          </div>
          <span> Andrama Bygg</span>
        </a>
      </div>
      <div className="burger-menu">
        <div className="burger-btn" onClick={handleToggle}>
          {burgerOpen ? (
            <FontAwesomeIcon icon={faXmark} onClick={onClose} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </div>
        <div
          className={`burger-list ${burgerOpen ? "show-menu onOpen" : ""}`}
          ref={burgerList}
        >
          <Link to="/" className="nav-home" title="Home">
            <img src={LogoMenu} alt="Logo to home"></img>
          </Link>
          <NavLink className="nav-link" to="/about">
            {data.about}
          </NavLink>
          <NavLink className="nav-link" to="/projects">
            {data.projects}
          </NavLink>
          <NavLink className="nav-link" to="/vacancies">
            {data.vacancies}
          </NavLink>
          <NavLink className="nav-link" to="/contact">
            {data.contact}
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default Header;
