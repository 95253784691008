import Header from "../components/Header";
import Footer from "../components/Footer";
import SubmitError from "../components/SubmitError";

const SubmitErrorPage = (props) => {
  const { useDocumentTitle } = props;
  useDocumentTitle("ANDRAMA - Error. Something wen wrong.");
  return (
    <div id="submit-wrapper">
      <Header />
      <SubmitError />
      <Footer />
    </div>
  );
};

export default SubmitErrorPage;
