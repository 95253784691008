export const sectionContentAnimate = {
  hidden: { y: 200, opacity: 0 },
  shown: {
    y: 0,
    opacity: 1,
    transition: { bounce: 0.4, type: "spring", duration: 2 },
  },
};

export const sideAnimateRight = {
  hidden: { x: 200, opacity: 0 },
  shown: {
    x: 0,
    opacity: 1,
    transition: { bounce: 0.3, type: "spring", duration: 2 },
  },
};

export const sideAnimateLeft = {
  hidden: { x: -200, opacity: 0 },
  shown: {
    x: 0,
    opacity: 1,
    transition: { bounce: 0.4, type: "spring", duration: 2 },
  },
};

export const bottomAnimate = {
  hidden: { y: 200, opacity: 0 },
  shown: {
    y: 0,
    opacity: 1,
  },
};
export const sideAnimateLeftCustom = {
  hidden: { left: -100, opacity: 0 },
  shown: {
    left: 0,
    opacity: 1,
  },
};
