import Header from "../components/Header";
import Footer from "../components/Footer";
import JobDetail from "../components/VacanciesComponents/JobDetail";
import { motion } from "framer-motion";

const JobDetailPage = (props) => {
  const { useDocumentTitle } = props;
  useDocumentTitle("ANDRAMA - Vacancies");
  return (
    <motion.div
      id="job-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Header />
      <JobDetail />
      <Footer />
    </motion.div>
  );
};

export default JobDetailPage;
