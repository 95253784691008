import "./styles/Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import { dataEnglish } from "../data.js";

const SubmitSuccess = () => {
  const [data] = useState(dataEnglish.submitSuccess);

  return (
    <div id="submit-success">
      <h2>{data.message}</h2>
      <div>
        <FontAwesomeIcon icon={faThumbsUp} />
      </div>
      <Link to="/">
        <button>{data.button}</button>
      </Link>
    </div>
  );
};

export default SubmitSuccess;
