import { dataEnglish } from "../../data";
import { useNavigate } from "react-router-dom";

const OpeningsLv = () => {
  const data = dataEnglish.vacancies.lv;
  const navigate = useNavigate();

  const formatTitleForNavigation = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "")
      .trim()
      .replace(/\s+/g, "-")
      .replace(/-/g, "-");
  };

  const handleOpen = (position) => {
    const formattedTitle = formatTitleForNavigation(position.positionTitle);
    navigate(`/vacancies/${formattedTitle}-${position.id}?lang=lv`);
  };

  return (
    <div className="job-offers-wrapper">
      <h2>{data.title}</h2>
      <div className="offers-list">
        {data.positions.map((position, index) => (
          <div className="offers-item" key={index}>
            <h3>{position.positionTitle}</h3>
            <button onClick={() => handleOpen(position)}>
              {data.buttonTitle}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OpeningsLv;
