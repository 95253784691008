import "./styles/ProjectsContent.css";
import { useState, useEffect } from "react";
import { dataEnglish } from "../data.js";
import HeroImage from "../images/projects_hero.jpg";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { sectionContentAnimate } from "../helpers.js";
import { motion } from "framer-motion";

const ProjectsContent = () => {
  const data = dataEnglish.projects;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 920);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 920);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <main>
      <section id="section-1">
        <div className="projects-intro-wrapper">
          <img src={HeroImage} loading="lazy" alt="Andrama hero" />
          <div className="content-wrapper">
            <h1>{data.title}</h1>
          </div>
        </div>
      </section>
      <section id="section-2">
        {data.list
          .sort((a, b) => b.id - a.id)
          .map((project, index) => (
            <motion.div
              key={index}
              className="project-item"
              style={
                !isMobile
                  ? {
                      flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                    }
                  : {}
              }
              variants={sectionContentAnimate}
              initial={"hidden"}
              whileInView={"shown"}
              viewport={{ once: true, amount: 0.2 }}
            >
              <div className="text" key={index}>
                <div
                  style={index % 2 === 0 ? { left: "15%" } : { right: "15%" }}
                >
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                </div>
              </div>
              <div className="gallery">
                <ImageGallery
                  items={project.image.map((img) => ({
                    original: img,
                  }))}
                  showThumbnails={false}
                  showPlayButton={false}
                  showFullscreenButton={isMobile ? false : true}
                  lazyLoad={true}
                  slideInterval={5000}
                />
              </div>
            </motion.div>
          ))}
      </section>
    </main>
  );
};

export default ProjectsContent;
