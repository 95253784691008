import "./styles/Vacancies.css";
import { useState, useEffect } from "react";
import HeroImage from "../images/office_bg.jpg";
import OpeningsEng from "./VacanciesComponents/OpeningsEng";
import OpeningsLv from "./VacanciesComponents/OpeningsLv";
import OpeningsSwe from "./VacanciesComponents/OpeningsSwe";
import OpeningsRus from "./VacanciesComponents/OpeningsRus";

const Vacancies = () => {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const storedLanguage = sessionStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);
  useEffect(() => {
    sessionStorage.setItem("language", language);
  }, [language]);

  const contentToRender = (lang) => {
    switch (lang) {
      case "en":
        return <OpeningsEng />;
      case "se":
        return <OpeningsSwe />;
      case "lv":
        return <OpeningsLv />;
      case "rus":
        return <OpeningsRus />;
      default:
        return <OpeningsEng />;
    }
  };

  const handleLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <main>
      <section id="section-1">
        <div className="vacancies-intro-wrapper">
          <img src={HeroImage} loading="lazy" alt="Andrama hero" />
          <div className="content-wrapper">
            <h1>Join our team</h1>
          </div>
        </div>
      </section>
      <section>
        <div className="choose-language-wrapper">
          {language !== "en" && (
            <button onClick={() => handleLanguage("en")}>English</button>
          )}
          {language !== "lv" && (
            <button onClick={() => handleLanguage("lv")}>Latviešu</button>
          )}
          {language !== "se" && (
            <button onClick={() => handleLanguage("se")}>Svenska</button>
          )}
          {language !== "rus" && (
            <button onClick={() => handleLanguage("rus")}>По-русски</button>
          )}
        </div>
      </section>
      <section id="section-2">{contentToRender(language)}</section>
    </main>
  );
};

export default Vacancies;
