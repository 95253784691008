const LogoLong = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1191.000000 317.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,317.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M950 2345 c-333 -113 -606 -205 -606 -205 -4 0 -2 -156 2 -162 2 -3
279 85 615 197 l611 204 37 -18 c20 -11 298 -163 619 -340 321 -177 586 -321
588 -321 3 0 3 39 2 86 l-3 86 -105 58 c-58 31 -336 184 -619 339 -283 154
-519 281 -525 280 -6 0 -283 -92 -616 -204z"
        />
        <path
          d="M958 2086 c-329 -94 -603 -177 -608 -182 -14 -14 -9 -140 5 -138 9 2
1133 324 1195 343 21 6 135 -45 640 -286 338 -161 618 -293 623 -293 4 0 7 33
7 73 l0 73 -247 116 c-137 64 -415 196 -620 292 -205 97 -378 176 -385 175 -7
0 -282 -78 -610 -173z"
        />
        <path
          d="M5404 2203 c-4 -15 -62 -233 -130 -483 -67 -250 -119 -459 -115 -463
4 -4 48 -7 96 -5 l89 3 16 65 c10 36 23 84 29 108 l12 43 111 -3 111 -3 27
-95 c14 -53 29 -101 34 -108 10 -17 175 -17 181 1 2 6 -54 226 -124 487 l-128
475 -101 3 -101 3 -7 -28z m137 -445 l26 -93 -50 -3 c-27 -2 -52 -1 -54 2 -7
6 39 186 47 186 3 0 17 -42 31 -92z"
        />
        <path
          d="M6082 1743 l3 -488 93 -3 92 -3 0 241 c0 132 4 240 8 240 4 0 55
-107 113 -237 l105 -238 92 -3 92 -3 0 491 0 490 -95 0 -95 0 0 -230 c0 -126
-4 -230 -8 -230 -4 0 -52 104 -107 230 l-99 230 -98 0 -98 0 2 -487z"
        />
        <path
          d="M6970 1739 l0 -491 233 4 c217 4 235 5 285 27 30 14 68 41 87 64 60
70 65 99 65 397 0 312 -7 349 -74 416 -65 66 -108 74 -373 74 l-223 0 0 -491z
m408 287 c61 -31 67 -57 67 -281 0 -174 -2 -204 -18 -233 -27 -49 -84 -72
-179 -72 l-78 0 0 304 0 304 88 -2 c57 -2 99 -9 120 -20z"
        />
        <path
          d="M7910 1740 l0 -491 93 3 92 3 3 128 3 128 41 -3 41 -3 57 -125 56
-125 94 -3 c51 -1 97 1 101 5 4 4 -22 70 -57 147 l-63 138 22 19 c12 10 25 18
28 19 3 0 19 24 35 53 l29 52 0 185 c0 175 -1 188 -24 233 -13 26 -39 60 -57
74 -61 46 -95 52 -301 53 l-193 0 0 -490z m355 265 c23 -22 25 -31 25 -129 0
-96 -2 -107 -24 -133 -21 -24 -32 -28 -95 -31 l-71 -4 0 161 0 161 70 0 c62 0
74 -3 95 -25z"
        />
        <path
          d="M8817 1757 c-70 -260 -127 -481 -127 -490 0 -16 10 -17 93 -15 l92 3
29 108 30 107 110 0 111 0 30 -108 30 -107 93 -3 c78 -2 92 0 92 13 0 17 -249
945 -256 957 -3 5 -49 8 -103 8 l-97 0 -127 -473z m278 -74 c5 -22 2 -23 -50
-23 -30 0 -55 3 -55 6 0 3 7 29 14 57 8 29 20 72 27 97 l11 45 24 -80 c13 -44
26 -90 29 -102z"
        />
        <path
          d="M9615 2218 c-3 -7 -4 -227 -3 -488 l3 -475 90 0 90 0 3 263 c1 144 6
262 11 262 4 0 34 -96 65 -212 32 -117 64 -237 73 -266 l15 -53 66 3 66 3 68
230 c38 127 74 248 81 270 10 35 13 4 17 -230 l5 -270 90 0 90 0 3 488 2 488
-112 -3 -112 -3 -96 -292 c-53 -161 -99 -296 -103 -299 -3 -3 -13 20 -22 53
-9 32 -49 166 -89 298 l-71 240 -113 3 c-84 2 -114 -1 -117 -10z"
        />
        <path
          d="M10915 2218 c-25 -73 -254 -952 -250 -959 4 -5 46 -9 95 -9 l89 0 12
43 c6 23 19 71 28 107 l18 65 111 3 110 3 16 -56 c9 -30 23 -80 31 -110 l16
-55 89 0 c49 0 91 3 93 8 3 4 -53 223 -124 486 -71 264 -129 481 -129 483 0 2
-45 3 -100 3 -72 0 -102 -4 -105 -12z m134 -461 l29 -97 -58 0 -58 0 26 99
c15 55 28 99 29 97 2 -1 16 -46 32 -99z"
        />
        <path
          d="M949 1831 c-322 -81 -591 -151 -598 -155 -7 -5 -11 -30 -10 -67 2
-54 4 -58 23 -54 12 3 283 70 604 150 320 80 591 145 601 145 9 0 294 -112
631 -250 337 -137 615 -250 617 -250 1 0 3 29 3 64 0 52 -3 65 -17 69 -10 3
-288 116 -617 251 -330 135 -611 246 -625 245 -14 0 -290 -67 -612 -148z"
        />
        <path
          d="M3303 1823 l-433 -145 0 -59 c0 -32 1 -59 3 -59 1 0 196 65 433 144
l431 143 374 -206 c206 -113 385 -214 399 -223 14 -9 42 -24 63 -33 l37 -15 0
59 0 59 -432 238 c-237 132 -434 240 -437 241 -3 1 -201 -64 -438 -144z"
        />
        <path
          d="M3575 1720 c-88 -25 -283 -82 -432 -125 l-273 -78 0 -49 c0 -32 4
-48 13 -48 6 0 201 54 433 121 l421 121 364 -172 c200 -95 397 -188 437 -206
l73 -34 -3 52 -3 51 -55 28 c-40 21 -615 293 -800 379 -8 4 -87 -14 -175 -40z"
        />
        <path
          d="M960 1582 c-322 -70 -593 -131 -602 -135 -14 -5 -17 -17 -17 -57 1
-29 6 -50 13 -50 6 0 283 59 616 131 l606 130 569 -201 c314 -111 594 -211
623 -221 l53 -18 -3 55 -3 56 -605 216 c-333 118 -618 216 -635 218 -16 2
-293 -54 -615 -124z"
        />
        <path
          d="M3305 1462 l-430 -108 -3 -42 c-2 -23 1 -42 6 -42 5 0 201 47 435
105 l425 105 234 -96 c128 -53 319 -131 425 -175 106 -43 197 -79 203 -79 7 0
10 16 8 42 l-3 42 -434 178 c-239 98 -435 178 -435 178 -1 -1 -194 -49 -431
-108z"
        />
        <path
          d="M940 1310 c-327 -54 -595 -99 -596 -99 0 -1 -1 -17 -2 -36 -1 -30 2
-35 21 -35 13 0 291 43 618 96 l594 95 75 -20 c41 -11 316 -84 610 -162 294
-78 541 -144 548 -147 10 -3 12 6 10 39 l-3 43 -610 162 c-335 90 -623 163
-640 162 -16 0 -298 -44 -625 -98z"
        />
        <path
          d="M3300 1286 l-425 -93 -3 -36 c-2 -21 0 -37 5 -37 4 0 200 41 435 91
l427 92 424 -151 c234 -84 430 -152 436 -152 8 0 11 13 9 36 l-3 36 -434 154
c-238 85 -436 154 -440 153 -3 0 -197 -42 -431 -93z"
        />
        <path
          d="M3305 1100 c-231 -38 -424 -69 -428 -70 -5 0 -7 -12 -5 -27 2 -18 8
-27 18 -26 8 1 203 32 432 69 l418 67 422 -113 c232 -62 428 -115 435 -117 9
-4 13 5 13 26 0 17 -2 31 -4 31 -3 0 -191 49 -418 109 -227 61 -424 112 -438
115 -14 2 -214 -27 -445 -64z"
        />
        <path
          d="M940 1055 c-327 -41 -596 -74 -598 -74 -2 -1 -2 -13 0 -28 2 -23 8
-28 28 -26 14 1 291 34 615 73 l590 71 609 -120 c335 -67 615 -121 623 -121
10 0 13 9 11 28 l-3 27 -610 122 c-335 67 -623 122 -640 121 -16 0 -298 -33
-625 -73z"
        />
        <path
          d="M3305 920 c-209 -26 -391 -49 -405 -51 -32 -5 -41 -39 -10 -39 12 0
208 23 435 50 l414 51 413 -81 c227 -45 423 -84 436 -87 17 -4 22 -1 22 16 0
12 -1 21 -3 21 -2 0 -194 38 -426 85 -233 47 -440 84 -460 84 -20 -1 -207 -23
-416 -49z"
        />
        <path
          d="M1535 894 c-11 -2 -280 -31 -599 -64 -318 -33 -583 -63 -587 -66 -5
-3 -9 -39 -9 -80 l0 -74 1241 0 1240 0 -3 38 -3 37 -335 58 c-184 31 -464 80
-622 107 -158 28 -291 49 -295 49 -5 -1 -17 -3 -28 -5z"
        />
        <path
          d="M5066 863 c-3 -21 -17 -86 -30 -145 l-23 -108 22 0 c14 0 25 7 28 20
4 14 14 20 35 20 22 0 32 -6 37 -20 4 -12 15 -20 29 -20 l23 0 -31 143 c-30
136 -33 142 -57 145 -23 3 -27 -1 -33 -35z m44 -144 c0 -10 -4 -19 -10 -19 -5
0 -10 12 -10 26 0 14 4 23 10 19 6 -3 10 -15 10 -26z"
        />
        <path
          d="M5275 890 c-3 -5 -3 -71 1 -145 l7 -135 53 0 c47 0 54 2 54 20 0 16
-7 20 -35 20 l-35 0 0 125 c0 117 -1 125 -19 125 -11 0 -23 -4 -26 -10z"
        />
        <path
          d="M5482 753 c2 -111 6 -148 16 -147 6 1 32 2 57 3 38 1 45 4 45 21 0
16 -7 20 -35 20 l-35 0 0 125 0 125 -26 0 -26 0 4 -147z"
        />
        <path
          d="M5690 881 c0 -13 8 -21 23 -23 21 -3 22 -8 27 -128 5 -117 6 -125 25
-125 19 0 20 8 25 125 5 120 6 125 28 128 14 2 22 10 22 23 0 17 -8 19 -75 19
-67 0 -75 -2 -75 -19z"
        />
        <path d="M6070 755 l0 -145 25 0 25 0 0 145 0 145 -25 0 -25 0 0 -145z" />
        <path
          d="M6212 753 c3 -144 4 -148 25 -151 21 -3 22 0 25 85 l3 88 36 -85 c27
-65 40 -85 55 -85 18 0 19 10 22 148 l3 147 -26 0 -27 0 4 -80 c2 -44 0 -80
-3 -80 -4 0 -21 36 -39 80 -28 69 -36 80 -56 80 l-25 0 3 -147z"
        />
        <path
          d="M6533 890 c-63 -25 -86 -163 -40 -231 32 -48 68 -63 113 -49 40 14
64 45 74 100 23 127 -52 218 -147 180z m76 -51 c28 -30 36 -97 18 -141 -19
-46 -51 -55 -83 -23 -32 33 -34 119 -3 159 25 32 42 33 68 5z"
        />
        <path
          d="M6807 893 c-4 -7 -25 -190 -31 -268 -1 -13 5 -20 19 -20 17 0 21 10
29 63 5 34 13 62 17 62 4 0 13 -26 21 -57 11 -46 18 -59 35 -61 18 -3 24 4 33
40 21 81 21 82 31 67 5 -8 9 -31 9 -51 0 -40 22 -70 45 -62 8 4 13 9 10 14 -4
7 -35 241 -35 267 0 7 -11 13 -24 13 -25 0 -25 1 -46 -102 -6 -32 -15 -58 -20
-58 -9 0 -22 40 -38 115 -6 30 -13 41 -29 43 -12 2 -23 0 -26 -5z"
        />
        <path
          d="M7260 755 l0 -145 39 0 c53 0 69 8 86 41 20 38 19 62 -4 90 -17 21
-17 26 -4 46 19 29 9 78 -20 98 -12 8 -38 15 -59 15 l-38 0 0 -145z m75 65 c0
-13 -6 -25 -12 -27 -9 -3 -13 6 -13 27 0 21 4 30 13 28 6 -3 12 -15 12 -28z
m3 -122 c-4 -46 -28 -49 -28 -4 0 26 4 36 16 36 11 0 14 -8 12 -32z"
        />
        <path
          d="M7490 755 l0 -145 60 0 c57 0 60 1 60 25 0 22 -4 25 -33 25 -42 0
-44 2 -39 44 4 31 8 35 36 38 23 2 32 8 34 26 3 19 -1 22 -31 22 -38 0 -49 13
-36 48 6 16 16 22 39 22 23 0 30 4 30 20 0 18 -7 20 -60 20 l-60 0 0 -145z"
        />
        <path
          d="M7700 881 c0 -13 8 -21 23 -23 21 -3 22 -8 27 -128 4 -109 7 -125 23
-128 14 -3 17 4 17 45 0 26 3 85 7 131 6 78 7 82 29 82 17 0 24 6 24 20 0 18
-7 20 -75 20 -67 0 -75 -2 -75 -19z"
        />
        <path
          d="M7974 866 c-32 -32 -34 -38 -34 -100 0 -88 19 -132 65 -152 44 -18
75 -11 111 27 24 25 29 40 32 94 5 74 -8 116 -46 145 -39 32 -88 26 -128 -14z
m105 -32 c16 -20 21 -41 21 -83 0 -77 -27 -109 -77 -90 -44 16 -52 135 -11
178 25 28 42 26 67 -5z"
        />
        <path
          d="M8240 755 c0 -89 4 -146 10 -146 34 -6 35 -2 40 80 l5 83 35 -83 c28
-65 40 -84 55 -84 19 0 20 8 23 148 l3 147 -26 0 c-25 0 -25 -1 -25 -82 0 -47
-4 -78 -9 -72 -4 5 -21 42 -36 82 -24 61 -32 72 -52 72 l-23 0 0 -145z"
        />
        <path
          d="M8556 879 c-55 -43 -73 -135 -40 -204 24 -51 82 -81 131 -69 21 6 23
12 23 74 0 60 -3 69 -20 75 -11 3 -29 3 -40 0 -23 -7 -27 -35 -6 -35 19 0 27
-60 9 -67 -42 -16 -79 94 -54 156 15 34 52 54 87 45 21 -5 24 -3 24 20 0 24
-3 26 -44 26 -29 0 -52 -7 -70 -21z"
        />
        <path
          d="M8951 874 c-76 -63 -60 -225 25 -260 32 -14 39 -13 72 3 46 22 72 72
72 136 0 64 -22 112 -59 131 -43 23 -75 20 -110 -10z m94 -39 c20 -19 25 -34
25 -75 0 -57 -11 -84 -40 -100 -67 -36 -109 121 -49 181 25 25 35 24 64 -6z"
        />
        <path
          d="M9266 879 c-38 -30 -56 -71 -56 -129 0 -88 42 -140 112 -140 31 0 38
4 38 20 0 15 -7 20 -30 20 -56 0 -90 67 -70 140 12 46 28 60 69 63 25 2 31 6
31 25 0 18 -5 22 -34 22 -19 0 -45 -9 -60 -21z"
        />
        <path
          d="M9452 753 c3 -140 4 -148 23 -148 18 0 20 8 23 63 l3 62 34 0 35 0 0
-66 c0 -62 1 -65 23 -62 22 3 22 6 25 151 l3 147 -26 0 c-24 0 -25 -2 -25 -60
l0 -60 -35 0 -35 0 0 60 c0 58 -1 60 -25 60 l-26 0 3 -147z"
        />
        <path
          d="M9850 756 c0 -80 4 -147 9 -150 4 -3 31 -3 60 1 41 5 51 9 51 24 0
15 -8 19 -35 19 l-35 0 0 125 0 125 -25 0 -25 0 0 -144z"
        />
        <path
          d="M10060 757 c0 -79 3 -146 6 -149 3 -3 14 -4 25 -1 18 5 19 15 19 149
l0 144 -25 0 -25 0 0 -143z"
        />
        <path
          d="M10200 881 c0 -13 8 -21 23 -23 21 -3 22 -8 27 -128 5 -117 6 -125
25 -125 19 0 20 8 25 125 5 120 6 125 28 128 14 2 22 10 22 23 0 17 -8 19 -75
19 -67 0 -75 -2 -75 -19z"
        />
        <path
          d="M10440 755 l0 -145 60 0 c57 0 60 1 60 25 0 22 -4 25 -35 25 -34 0
-35 1 -35 39 0 37 2 40 33 43 24 2 33 8 35 26 3 19 -1 22 -33 22 -35 0 -36 1
-33 33 2 17 9 33 16 33 6 1 21 2 32 3 13 1 20 8 20 21 0 18 -7 20 -60 20 l-60
0 0 -145z"
        />
        <path
          d="M10829 898 c-1 -2 -9 -66 -18 -143 -12 -98 -14 -142 -7 -147 21 -14
31 -1 40 48 13 75 15 81 26 64 5 -8 12 -32 15 -52 8 -48 31 -74 51 -57 8 6 14
19 14 28 0 9 5 34 11 55 8 29 13 36 20 25 5 -8 9 -26 9 -41 0 -45 17 -77 37
-72 18 5 18 10 7 92 -7 48 -15 113 -19 145 -6 51 -9 57 -29 57 -20 0 -24 -9
-40 -80 -9 -44 -21 -80 -25 -80 -5 0 -17 35 -28 78 -16 65 -22 78 -41 80 -12
2 -23 2 -23 0z"
        />
        <path
          d="M11137 786 c-4 -62 -4 -128 -1 -145 6 -30 7 -31 65 -31 56 0 59 1 59
24 0 21 -5 25 -37 28 -36 3 -38 5 -38 38 0 32 3 35 35 40 27 4 36 10 38 28 3
19 -1 22 -33 22 -40 0 -53 17 -35 49 7 14 21 21 40 21 23 0 30 4 30 20 0 18
-7 20 -58 20 l-59 0 -6 -114z"
        />
        <path
          d="M11350 750 c0 -143 1 -150 20 -150 18 0 20 7 20 55 0 30 4 55 8 55 4
0 20 -25 35 -55 22 -45 31 -55 52 -55 14 0 25 2 25 5 0 3 -14 33 -31 66 -30
61 -30 61 -11 83 28 31 34 55 21 93 -13 41 -34 53 -93 53 l-46 0 0 -150z m85
60 c0 -25 -5 -36 -17 -38 -15 -3 -18 4 -18 38 0 34 3 41 18 38 12 -2 17 -13
17 -38z"
        />
        <path
          d="M3305 764 c-225 -25 -416 -47 -422 -49 -8 -3 -13 -23 -13 -55 l0 -50
870 0 870 0 0 23 c0 22 -7 25 -97 40 -54 9 -253 44 -443 77 -190 33 -347 59
-350 59 -3 -1 -189 -21 -415 -45z"
        />
      </g>
    </svg>
  );
};

export default LogoLong;
