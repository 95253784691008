import "./styles/AboutContent.css";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { dataEnglish } from "../data.js";
import {
  sideAnimateLeftCustom,
  sideAnimateRight,
  sectionContentAnimate,
  bottomAnimate,
} from "../helpers.js";
import HeroImage from "../images/armatura.jpg";
import Whoweare from "../images/whoweare.jpg";
import Concrete from "../images/concrete.jpg";
import Prefab from "../images/prefab_ai.jpg";

const AboutContent = () => {
  const [data] = useState(dataEnglish.about);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const yPosition = scrollY * 0.07;

  return (
    <main>
      <section id="section-1">
        <div className="about-intro-wrapper">
          <img src={HeroImage} loading="lazy" alt="Andrama hero" />
          <div className="content-wrapper">
            <h1>{data.title1}</h1>
          </div>
        </div>
      </section>
      <motion.section
        id="section-2"
        variants={sectionContentAnimate}
        initial={"hidden"}
        whileInView={"shown"}
        viewport={{ once: true, amount: 0.08 }}
      >
        <div className="whoweare-wrapper">
          <div className="text">
            <h2>{data.description1}</h2>
            <p>{data.description2}</p>
          </div>
          <motion.div
            className="media"
            variants={sideAnimateRight}
            initial={"hidden"}
            whileInView={"shown"}
            viewport={{ once: true, amount: 0.3 }}
          >
            <motion.img
              src={Whoweare}
              loading="lazy"
              alt="Person holding tool and cutting metal"
              style={{ transform: `translateY(${yPosition}px)` }}
            />
          </motion.div>
        </div>
      </motion.section>
      <section id="section-3">
        <div className="values-wrapper">
          <div className="text">
            <h2>
              {data.values} <span>{data.valuesSpan}</span>
            </h2>
          </div>
          <div className="media">
            {data.valuesList.map((item, index) => (
              <motion.div
                key={index}
                className="item-group"
                variants={bottomAnimate}
                initial={"hidden"}
                whileInView={"shown"}
                viewport={{ once: true, amount: 0.2 }}
                transition={{
                  duration: 1,
                  type: "spring",
                  bounce: 0.1,
                  delay: index * 0.35,
                }}
              >
                <FontAwesomeIcon icon={item.icon} />
                <p>
                  <span>{item.text}</span>
                  {item.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      <section id="section-4">
        <div className="weoffer-wrapper">
          <div className="text">
            {data.weofferTitle.map((item, index) => (
              <motion.span
                key={index}
                className="offer-title"
                variants={sideAnimateLeftCustom}
                initial={"hidden"}
                whileInView={"shown"}
                viewport={{ once: true, amount: 0.32 }}
                transition={{
                  ease: [0.42, 0, 0.58, 1],
                  duration: 0.8,
                  type: "spring",
                  bounce: 0.1,
                  delay: index * 0.4,
                }}
              >
                {item}
              </motion.span>
            ))}
          </div>
          <motion.div
            className="media"
            variants={sectionContentAnimate}
            initial={"hidden"}
            whileInView={"shown"}
            viewport={{ once: true, amount: 0.1 }}
          >
            <Carousel
              showStatus={false}
              autoPlay={true}
              interval={5000}
              transitionTime={1000}
              infiniteLoop={true}
              showIndicators={true}
              showThumbs={false}
              emulateTouch={true}
            >
              <div className="item-group">
                <div className="description">
                  <div>
                    <h3>Concrete</h3>
                    <p>{data.concrete}</p>
                  </div>
                </div>
                <span className="image-wrapper">
                  <img src={Concrete} loading="lazy" alt="Concrete wall" />
                </span>
              </div>
              <div className="item-group">
                <div className="description">
                  <div style={{ backgroundColor: "#1e194b" }}>
                    <h3>Prefab</h3>
                    <p>{data.prefab}</p>
                  </div>
                </div>
                <span className="image-wrapper">
                  <img src={Prefab} loading="lazy" alt="Prefab wall" />
                </span>
              </div>
            </Carousel>
          </motion.div>
        </div>
      </section>
    </main>
  );
};

export default AboutContent;
